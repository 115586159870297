import { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import liff from '@line/liff';
import { Profile } from "@liff/get-profile";
// import { signIn } from 'next-auth/react';
// import { useRouter } from 'next/navigation';
// import { redirect } from 'next/dist/server/api-utils';

// Define the shape of the context value
interface LiffContextType {
  liffObject: typeof liff | null;
  liffProfile: Profile | null;
  liffError: string | null;
}

const liffId = process.env.NEXT_PUBLIC_LIFF_ID;

// Create the context with an initial default value
const LiffContext = createContext<LiffContextType | undefined>(undefined);

interface LiffProviderProps {
  children: ReactNode;
}

export function LiffProvider({ children }: LiffProviderProps) {
  const [liffObject, setLiffObject] = useState<typeof liff | null>(null);
  const [liffError, setLiffError] = useState<string | null>(null);
  const [liffProfile, setLiffProfile] = useState<Profile | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initializeLiff = async () => {
      console.log("start initializing LIFF");
      
      if (!liffId) {
        setLiffError("LIFF ID is not provided");
        return;
      }

      try {
        await liff.init({
          liffId: liffId || '',
          withLoginOnExternalBrowser: true,
        });

        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          setLiffProfile(profile);
          setLiffObject(liff);
        } else {
          liff.login();
        }
        setInitialized(true);
      } catch (error) {
        setLiffError("LIFF initialization failed");
        console.error(error);
        window.location.reload(); 
      }
    }

    if (!initialized) {
      initializeLiff();
    }
  }, [initialized]);

  return (
    <LiffContext.Provider value={{ liffObject, liffProfile, liffError }}>
      {children}
    </LiffContext.Provider>
  );
}

export function useLiff() {
  const context = useContext(LiffContext);
  if (context === undefined) {
    throw new Error("useLiff must be used within a LiffProvider");
  }
  return context;
}